// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "output", "button", "divarea" ]


  kalkuler(e){
    e.preventDefault()
    this.outputTarget.value = this.calculate(this.parseCalculationString(this.inputTarget.value))
    this.divareaTarget.classList.toggle("hidden")
  }

  vise(){
    this.divareaTarget.classList.toggle("hidden")
  }

  // --- Parse a calculation string into an array of numbers and operators
  parseCalculationString(s) {
      var calculation = [],
          current = '';
      for (var i = 0, ch; ch = s.charAt(i); i++) {
          if ('^*/+-'.indexOf(ch) > -1) {
              if (current == '' && ch == '-') {
                  current = '-';
              } else {
                  calculation.push(parseFloat(current), ch);
                  current = '';
              }
          } else {
              current += s.charAt(i);
          }
      }
      if (current != '') {
          calculation.push(parseFloat(current));
      }
      return calculation;
  }


  calculate(calc) {
      // --- Perform a calculation expressed as an array of operators and numbers
      var ops = [{
              '^': function(a, b) {
                  return Math.pow(a, b);
              }
          }, {
              '*': function(a, b) {
                  return a * b
              },

              '/': function(a, b) {
                  return a / b
              },
          }, {
              '+': function(a, b) {
                  return a + b
              },

              '-': function(a, b) {
                  return a - b
              }
          }],
          newCalc = [],
          currentOp;
      for (var i = 0; i < ops.length; i++) {
          for (var j = 0; j < calc.length; j++) {
              if (ops[i][calc[j]]) {
                  currentOp = ops[i][calc[j]];
              } else if (currentOp) {
                  newCalc[newCalc.length - 1] = currentOp(newCalc[newCalc.length - 1], calc[j]);
                  currentOp = null;
              } else {
                  newCalc.push(calc[j]);
              }
              console.log(newCalc);
          }
          calc = newCalc;
          newCalc = [];
      }
      if (calc.length > 1) {
          console.log('Error: unable to resolve calculation');
          return calc;
      } else {
          return calc[0];
      }
  }


}
