// Calulate mortgage payments


import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "interest" ]


  interest_changed(e){
    // Get Data attributes
    var paymenttype = e.target.getAttribute('data-paymenttype')

    // Get value of the field
    this.rawValue = e.target.value

    // Get the output Target
    var selectedtarget = this.targets.find(paymenttype)

    // Calculate new monthly payment amount
    selectedtarget.value = this.calculate(this.amount.value, this.term.value, this.rawValue)

    //Format interest rates to include % and two spaces
    this.ratesformat();
  }


  // Siden vi ikke har targets
  interest_loop_all(e){
    // Get Data attributes
    var paymenttype = e.getAttribute('data-paymenttype')

    // Get value of the field
    this.rawValue = e.value

    // Get the output Target
    var selectedtarget = this.targets.find(paymenttype)

    // Calculate new monthly payment amount
    selectedtarget.value = this.calculate(this.amount.value, this.term.value, this.rawValue)
  }



  term_amount_changed(){
    this.interestTargets.forEach((el, i) => {
      this.interest_loop_all(el);
    })
    //Fix format
    this.moneyformat();
  }



  ltv_changed(){
    var rawAmount = this.amount.value.replace(/[^0-9]+/g, '');
    var rawPrice = this.price.value.replace(/[^0-9]+/g, '');

    var stripped_amount = parseInt(rawAmount);
    var stripped_price = parseInt(rawPrice);

    var ltv = stripped_amount/stripped_price
    console.log("ltv er: " + ltv)

    this.ltv.value = Math.round(ltv*100) + " %"
    this.moneyformat();
  }



  calculate(amount, term, interest){
    var rawAmount = amount.replace(/[^0-9]+/g, '');
    var rawTerm = term.replace(/[^0-9]+/g, '');
    var rawInterest = interest.replace(/[^0-9.,]+/g, '');

    var stripped_amount = parseInt(rawAmount);
    var stripped_term = (parseInt(rawTerm)*12);
    var stripped_interest = ((parseFloat(rawInterest.replace(',', '.'))/100)/12);

    var payment = this.monthlypayments(stripped_amount, stripped_term, stripped_interest)

    return payment.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }


  // Calculate
  monthlypayments(amount, term, interest) {
    return amount * interest * (Math.pow(1 + interest, term)) / (Math.pow(1 + interest, term) - 1);
  }


  // Fix amount format
  moneyformat(){
    var rawAmount = this.amount.value.replace(/[^0-9]+/g, '');
    var rawPrice = this.price.value.replace(/[^0-9]+/g, '');

    var stripped_amount = parseInt(rawAmount);
    var stripped_price = parseInt(rawPrice);

    this.amount.value = stripped_amount.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.price.value = stripped_price.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }

  ratesformat(){
    this.interestTargets.forEach((el, i) => {
      var rawVal = el.value.replace(/[^0-9.,]+/g, '');
      var stripped_val = rawVal.replace(',', '.')
      el.value = parseFloat(stripped_val).toFixed(2) + " %";
    })
  }

  tgmformat(rawValue){
    return rawValue.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }


  // Get Targets Pay amount
  get monthly_pay_minv() {
    return this.targets.find('monthly_pay_minv')
  }
  get monthly_pay_maxv() {
    return this.targets.find('monthly_pay_maxv')
  }
  get monthly_pay_minfix() {
    return this.targets.find('monthly_pay_minfix')
  }
  get monthly_pay_maxfix() {
    return this.targets.find('monthly_pay_maxfix')
  }

  // Get term and amount and price and ltv
  get term() {
    return this.targets.find('term')
  }
  get amount() {
    return this.targets.find('amount')
  }
  get price() {
    return this.targets.find('price')
  }
  get ltv() {
    return this.targets.find('ltv')
  }

  get areacode() {
    return this.targets.find('areacode')
  }
  get isnew() {
    return this.targets.find('isnew')
  }

  // Breakdown inputs
  get punotary() {
    return this.targets.find('punotary')
  }
  get puregister() {
    return this.targets.find('puregister')
  }
  get putaxitp() {
    return this.targets.find('putaxitp')
  }
  get putaxajd() {
    return this.targets.find('putaxajd')
  }
  get puadmin() {
    return this.targets.find('puadmin')
  }
  get putotal() {
    return this.targets.find('putotal')
  }
  get valuation() {
    return this.targets.find('valuation')
  }
  get opening() {
    return this.targets.find('opening')
  }
  get bis() {
    return this.targets.find('bis')
  }
  get mortgagetotal() {
    return this.targets.find('mortgagetotal')
  }
  get savings() {
    return this.targets.find('savings')
  }



  // AJAX update part
  fetchcosts() {
    //Strip values
    var rawAmount = this.amount.value.replace(/[^0-9]+/g, '');
    var rawPrice = this.price.value.replace(/[^0-9]+/g, '');
    var areacode = this.areacode.value
    var isnew = this.isnew.checked

    Rails.ajax({
      type: "GET",
      url: "calculate_mortgage_costs",
      data: "mortgage_amount=" + rawAmount + "&purchase_amount=" + rawPrice + "&area_code=" + areacode + "&is_new_build=" + isnew,
      success: (data) => {
        console.log('Alt Units were got! And is new is ' + isnew)
        this.fillBreakdownValues(data)
      }
    })
  }

  fillBreakdownValues(data){
    this.punotary.value = data.purchase_notary.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.puregister.value = data.purchase_register.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.puadmin.value = data.purchase_admin.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.putaxitp.value = data.purchase_itp_iva.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.putaxajd.value = data.purchase_ajd.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.putotal.value = data.purchase_total.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.valuation.value = data.valuation.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.opening.value = data.opening.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.bis.value = data.bis.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.mortgagetotal.value = data.mortgage_total.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    this.savings.value = data.total_needed.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }

  sumvalues(){
    var rawpunotary =   this.stripvalues(this.punotary.value)
    var rawpuregister =   this.stripvalues(this.puregister.value)
    var rawpuadmin =   this.stripvalues(this.puadmin.value)
    var rawputaxitp =   this.stripvalues(this.putaxitp.value)
    var rawputaxajd =   this.stripvalues(this.putaxajd.value)
    var totalpurchase =  parseInt(rawpunotary) + parseInt(rawpuregister) + parseInt(rawpuadmin) + parseInt(rawputaxitp) + parseInt(rawputaxajd)

    var rawvaluation =   this.stripvalues(this.valuation.value)
    var rawopening=   this.stripvalues(this.opening.value)
    var rawbis =   this.stripvalues(this.bis.value)
    var mortgagetotal = parseInt(rawvaluation) + parseInt(rawopening) + parseInt(rawbis)

    var purchaseprice = this.stripvalues(this.price.value)
    var mortgageamount = this.stripvalues(this.amount.value)

    var savings = parseInt(purchaseprice) + parseInt(totalpurchase) + parseInt(mortgagetotal) - parseInt(mortgageamount)

    // Print new values to the form
    this.punotary.value = rawpunotary.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.puregister.value = rawpuregister.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.puadmin.value = rawpuadmin.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.putaxitp.value = rawputaxitp.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.putaxajd.value = rawputaxajd.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.putotal.value = totalpurchase.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });

    this.valuation.value = rawvaluation.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.opening.value = rawopening.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.bis.value = rawbis.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.mortgagetotal.value = mortgagetotal.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    this.savings.value = savings.toLocaleString('se', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 });

  }

  stripvalues(formattedtext){
    return parseInt(formattedtext.replace(/[^0-9]+/g, ''));
  }







}
