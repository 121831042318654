// Selecting areas on the map

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]


  // The user is cliking on the map
  kartklikk(e){
    this.deselectmap()
    e.target.style.fill = "#BD1616"
    var areacode = e.target.getAttribute('data-area-code');
    this.velgregion(areacode)
  }

  // Making all regions gray
  deselectmap(){
    this.map.querySelectorAll('path').forEach(function(el) {
      el.style.fill = "CCCCCC"
    });
  }

  // Select dropdown value in form based on map click
  velgregion(reg){
    // Velg region i dropdown
    try{
      this.region.value = reg;
      var navn = this.region.options[this.region.selectedIndex].text;
      this.skrivregion(navn);
    } catch(err) {
      this.region.value = 0;
      var navn = this.region.options[this.region.selectedIndex].text;
      this.skrivregion(navn);
    }

  }

  // Display the selected region name on top of the map
  skrivregion(regionsnavn){
    this.regionsnavn.innerHTML = regionsnavn;
  }


  regiondropdownchange(){
    var selreg = this.region.value;
    var selregname = this.region.options[this.region.selectedIndex].text;
    this.deselectmap()

    this.skrivregion(selregname)

    this.map.querySelectorAll('path').forEach(function(el) {
      if(el.getAttribute('data-area-code') == selreg){
        el.style.fill = "BD1616"
      }
    });
  }








  // Get values
  get map() {
    return this.targets.find('kart')
  }

  get region() {
    return this.targets.find('region')
  }

  get regionsnavn() {
    return this.targets.find('regionsnavn')
  }

}
