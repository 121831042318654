// Kopier text fra button til textfield

import { Controller } from "stimulus"

export default class extends Controller {

  kopiertekst(e){
    // Get Data attributes
    var kommentar = e.target.getAttribute('data-kommentar')
    this.tekstfeltet.value = kommentar;

  }

  get tekstfeltet() {
    return this.targets.find('tekstfeltet')
  }

}
