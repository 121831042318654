import { Controller } from "stimulus"

export default class extends Controller {

  flip(){
    this.card.classList.toggle("flipped");
    this.bakside.classList.toggle("hidden");
    this.forside.classList.toggle("hidden");
  }


  // Get Targets
  get forside() {
    return this.targets.find('front')
  }

  get bakside() {
    return this.targets.find('back')
  }

  get card(){
    return this.targets.find('card')
  }


}
