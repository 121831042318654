// Controller to show or hide div areas in the mortgage
// quote request form

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "button" ]

  selected(e) {
    e.preventDefault()

    // Get Data attributes
    this.answer = e.target.innerHTML || e.event.srcElement.innerHTML

    // Show div and fill input in form
    this.fill(this.answer)

    // Set selected buttn css style
    this.standardbtn(e.target)
  }


  // Fill form input field
  fill(message){
    this.inputfield.value = message

    if(this.currencyfield1){
      this.currencyfield1.innerHTML = message;
    }

    if(this.currencyfield2){
      this.currencyfield2.innerHTML = message;
    }
  }

  // Remove selected css class from li tags
  standardbtn(btn){
    var alleli = btn.parentNode.getElementsByTagName('li')

    for( var x=0; x < alleli.length; x++ ) {
       alleli[x].classList.remove("active")
    }

    btn.classList.add("active")
  }


  get inputfield() {
    return this.targets.find('inputfield')
  }

  get currencyfield1() {
    return this.targets.find('currencyfield1')
  }

  get currencyfield2() {
    return this.targets.find('currencyfield2')
  }











}
