import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["gross", "tax", "net"]



  calculate(e){

    //Get the scope
    var scope = e.target.getAttribute('data-month');


    // Set all at 0 as a start
    var gross = 0.0
    var tax = 0.0
    var net = 0.0


    // Assign values if not empty
    this.grossTargets.forEach( (elem) => {
      if(elem.value && elem.getAttribute('data-month') == scope){
        gross = this.stripvalue(elem.value);

      }
    } )

    this.taxTargets.forEach( (elem) => {
      if(elem.value && elem.getAttribute('data-month') == scope){
        tax = this.stripvalue(elem.value);

      }
    } )

    this.netTargets.forEach( (elem) => {
      if(elem.value && elem.getAttribute('data-month') == scope){
        net = this.stripvalue(elem.value);

      }
    } )



    // Check which initial caluatation to do
    if(gross == 0 && tax != 0 && net != 0){
      this.grossTargets.forEach( (elem) => {
        if(elem.getAttribute('data-month') == scope){
          elem.value = (tax + net); //toFixed(2).toLocaleString('se-SE', { minimumFractionDigits: 2, maximumFractionDigits:2 });
        }
      })
    }

    if(gross != 0 && tax == 0 && net != 0){
      this.taxTargets.forEach( (elem) => {
        if(elem.getAttribute('data-month') == scope){
          elem.value = (gross - net); //toFixed(2).toLocaleString('se-SE', { minimumFractionDigits: 2, maximumFractionDigits:2 });
        }
      })
    }

    if(gross != 0 && tax != 0 && net == 0){
      this.netTargets.forEach( (elem) => {
        if(elem.getAttribute('data-month') == scope){
          elem.value = (gross - tax); //toLocaleString('se-SE', { minimumFractionDigits: 2, maximumFractionDigits:2 });
        }
      })
    }

    if(gross != 0 && tax != 0 && net != 0){
      this.taxcalculation(scope);
    }


  }

  // Do update tax calculation

  taxcalculation(scope){
    var gross = 0.0
    var net = 0.0

    this.grossTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        gross = this.stripvalue(elem.value);
      }
    })

    this.netTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        net = this.stripvalue(elem.value);
      }
    })

    this.taxTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        elem.value = (gross - net); //toLocaleString('se-SE', { minimumFractionDigits: 2, maximumFractionDigits:2 });
      }
    })


  }


  empty(e){
    var scope = e.target.getAttribute('data-month');

    // Assign values if not empty
    this.grossTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        elem.value = "";
      }
    } )

    this.taxTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        elem.value = "";
      }
    } )

    this.netTargets.forEach( (elem) => {
      if(elem.getAttribute('data-month') == scope){
        elem.value = "";
      }
    } )
  }


  stripvalue(iv){
    var nummer = iv.toString().replace(/\s/g, '');
    nummer = nummer.toString().replace(',', '.', 'gi');

    var num = parseFloat(nummer);

    return num;
  }



}
