// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application)


// Import and register all TailwindCSS Components
import { Dropdown, Modal, Tabs, Popover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)

// autocomplete added by Trond
import { Autocomplete } from 'stimulus-autocomplete'
application.register('autocomplete', Autocomplete)


//Scrollreveal
import ScrollReveal from 'scrollreveal'
document.addEventListener("turbolinks:load", () => {
  ScrollReveal().reveal('.reveal', { reset: true, distance: '50px', origin: 'top' })
  });
