import AutoNumeric from 'autonumeric';


// document.addEventListener("turbolinks:load", () => {
//   new AutoNumeric.multiple("[data-behaviour='tgdesimaltall']", {
//     decimalCharacter: ",",
//     decimalCharacterAlternative: ".",
//     digitGroupSeparator: " ",
//   });
// })





document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("[data-behaviour='tgdesimaltall']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalCharacter: ",",
      decimalCharacterAlternative: ".",
      digitGroupSeparator: " ",
      unformatOnSubmit: true,
      watchExternalChanges: true,
    });
  });

  document.querySelectorAll("[data-behaviour='tgheltall']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalPlaces: 0,
      decimalCharacter: ",",
      decimalCharacterAlternative: ".",
      digitGroupSeparator: " ",
      unformatOnSubmit: true,
      watchExternalChanges: true,
    });
  });

  document.querySelectorAll("[data-behaviour='tgprosent']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalPlaces: 2,
      decimalCharacter: ",",
      decimalCharacterAlternative: ".",
      digitGroupSeparator: " ",
      unformatOnSubmit: true,
      currencySymbol: "%",
      currencySymbolPlacement: "s",
      watchExternalChanges: true,
    });
  });

  document.querySelectorAll("[data-behaviour='tgeuroheltall']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalPlaces: 0,
      decimalCharacter: ",",
      decimalCharacterAlternative: ".",
      digitGroupSeparator: " ",
      unformatOnSubmit: true,
      currencySymbol: " €",
      currencySymbolPlacement: "s",
      watchExternalChanges: true,
    });
  });

  document.querySelectorAll("[data-behaviour='tgeurodesimaltall']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalPlaces: 2,
      decimalCharacter: ",",
      decimalCharacterAlternative: ".",
      digitGroupSeparator: " ",
      unformatOnSubmit: true,
      currencySymbol: " €",
      currencySymbolPlacement: "s",
      watchExternalChanges: true,
    });
  });

  document.querySelectorAll("[data-behaviour='tgaarstall']").forEach(function(el) {
    new AutoNumeric(el, {
      decimalPlaces: 0,
      digitGroupSeparator: "",
      unformatOnSubmit: true,
      watchExternalChanges: true,
      digitalGroupSpacing: "4",
      maximumValue: "9999",
    });
  });


})
