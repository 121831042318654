import { Controller} from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "address"]

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.marker()
    this.autocomplete()
    console.log('init')
  }

  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.latitudeTarget.value,
          this.longitudeTarget.value
        ),
        zoom: 17
      })
    }
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
      this._marker.setDraggable(true)
      this._marker.setAnimation(google.maps.Animation.DROP)
      this._marker.addListener('dragend', this.pinpositionChanged.bind(this))
    }
    return this._marker
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }


  pinpositionChanged() {
    let latlng = this.marker().getPosition();
    let newlat=(Math.round(latlng.lat()*1000000))/1000000;
    let newlng=(Math.round(latlng.lng()*1000000))/1000000;

    this.latitudeTarget.value = newlat
    this.longitudeTarget.value = newlng

    this.map().setCenter(this.marker().getPosition());

  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()

    // Set the address in the address field
    var address = '';
    if (place.address_components) {
        address = [
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[0] && place.address_components[0].short_name || ''),
          "\n",
          (place.address_components[6] && place.address_components[6].short_name || ''),
          (place.address_components[2] && place.address_components[2].long_name || ''),
          "(",
          (place.address_components[3] && place.address_components[3].long_name || ''),
          ")"
        ].join(' ');
      }
    this.addressTarget.value = address
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }






}
