import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "kilde" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.kildeTarget.classList.remove("hidden")
   }
  }

  copy() {
    var kundeinfo = this.kildeTarget.getAttribute('data-kundeinfo')

    // Give a copy text
    var original = this.kildeTarget.innerHTML;
    this.kildeTarget.innerHTML = 'Copied!';

    setTimeout(() => {
      this.kildeTarget.innerHTML = original;
    }, 1200);

    // Change to Input type value
    var myTemporaryInputElement = document.createElement("input");
    myTemporaryInputElement.type = "text";
    myTemporaryInputElement.value = kundeinfo;

    document.body.appendChild(myTemporaryInputElement);

    myTemporaryInputElement.select();
    document.execCommand("copy")

    document.body.removeChild(myTemporaryInputElement);


  }
}
