
// Caluclate mortgage Payments

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]



  //Display value
  calculate(){
    var rawAmount = this.amount.value.replace(/ /g, "");
    var rawInterest = this.interest.value.replace(/,/g, ".");

    var amount = parseInt(rawAmount);
    var term = (parseInt(this.term.value)*12);
    var interest = ((parseFloat(rawInterest)/100)/12);

    var payment = this.monthlypayments(amount, term, interest)

    this.outputTarget.innerHTML = payment.toLocaleString('se', { style: 'currency', currency: 'EUR' }) + " a month"

  }



  // Calculate
  monthlypayments(amount, term, interest) {
    return amount * interest * (Math.pow(1 + interest, term)) / (Math.pow(1 + interest, term) - 1);
  }


  // Get values
  get term() {
    return this.targets.find('term')
  }

  get amount() {
    return this.targets.find('amount')
  }

  get interest() {
    return this.targets.find('interest')
  }
}
