// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "purchase", "ltv", "mortgage" ]


  calculate(e){
    // Not posting form when pressing on button
    e.preventDefault()

    // Get the purchase price
    var purchacepriceraw = this.purchaseTarget.value.replace(/\s+/g, '')
    var purchacepriceint = parseInt(purchacepriceraw, 10);

    // Get the LTV
    var ltvraw = e.target.innerHTML || e.event.srcElement.innerHTML
    var ltvint = parseInt(ltvraw, 10);

    // Calculate Mortgage
    if(this.checknumber(purchacepriceint) && this.checknumber(ltvint)){
      var mortgagevalue = (purchacepriceint * ltvint)/100

      // Print out mortgage value
      this.mortgageTarget.value = parseInt(mortgagevalue)
    }

  }



  // Function to check if we have a number
  checknumber(intvalue){
    if(typeof intvalue === 'number' && intvalue === intvalue && intvalue !== Infinity && intvalue !== -Infinity && intvalue != 0){
      return true
    } else{
      return false
    }
  }


}
