import flatpickr from 'flatpickr';
require('flatpickr/dist/themes/airbnb.css')
//import 'flatpickr/dist/themes/airbnb.css';

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behaviour='datepicker']", {
    altInput: true,
    altFormat: "J F, Y",
    dateFormat: "Y-m-d",
    locale: {
        firstDayOfWeek: 1, // start week on Monday
    },
  });

  flatpickr("[data-behaviour='datetimepicker']", {
    altInput: true,
    enableTime: true,
    time_24hr: true,
    altFormat: "J F, Y - H:i",
    locale: {
       firstDayOfWeek: 1,
    },
    //dateFormat: "Y-m-d",
  });

  flatpickr("[data-behaviour='dateinputpicker']", {
    altInput: true,
    allowInput: true,
    altFormat: "J F, Y - H:i",
    locale: {
       firstDayOfWeek: 1,
    },
    //dateFormat: "Y-m-d",
  });

})
