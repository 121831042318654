// Controller to show or hide div areas in the mortgage
// quote request form

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "button" ]

  selected(e) {
    e.preventDefault()

    // Get Data attributes
    this.shouldShow = e.target.getAttribute('data-shouldshow')
    this.answer = e.target.innerHTML || e.event.srcElement.innerHTML

    // Show div and fill input in form
    this.additional_info(this.shouldShow)
    this.fill(this.answer)

    // Set selected buttn css style
    this.standardbtn(e.target)

  }

  // Toggle div with additional info
  additional_info(message){
    if(message == "false"){
      this.togglediv.classList.add("expandable")
    } else {
      this.togglediv.classList.remove("expandable")
    }
  }

  // Fill form input field
  fill(message){
    this.inputfield.value = message
  }

  // Remove selected css class from buttons
  standardbtn(btn){
    var alleli = btn.parentNode.getElementsByTagName('li')

    for( var x=0; x < alleli.length; x++ ) {
       alleli[x].classList.remove("active")
    }

    btn.classList.add("active")
  }

  // Get Targets
  get togglediv() {
    return this.targets.find('togglediv')
  }

  get inputfield() {
    return this.targets.find('inputfield')
  }











}
