import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "hamburger" ]

  shouldtoggle(event) {
         this.open_btn.classList.toggle("hidden")
         this.linkdiv.classList.toggle("hidden")
         this.close_btn.classList.toggle("hidden")
  }

  get close_btn() {
    return this.targets.find('close')
  }

  get open_btn() {
    return this.targets.find('open')
  }

  get linkdiv() {
    return this.targets.find('linkdiv')
  }

}
