// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//Added by Trond
require("chartkick").use(require("highcharts"))



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import "stylesheets/application"

import 'typeface-clear-sans'
import 'typeface-pt-serif'
import 'typeface-pt-sans'
import 'animate.css'
import 'tailwindcss-toggle'

//Import TG packages
import 'tgplugins/flatpickr'
import 'tgplugins/autonumeric'
//import '@tailwindcss/custom-forms'




// Import the Izitoast flash messages
import iziToast from 'izitoast/dist/js/iziToast';
window.iziToast = iziToast;

// Import the micromodal
import MicroModal from 'micromodal';









// Add Callback for Google Map APIs
window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
