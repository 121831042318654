// Calculate age from birthdate

import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['birthdate','age']


  agecalc(){
    this.ageTarget.textContent = "-";
    var fecha = ""
    if (this.sjekk() != "01/01/0000"){
      fecha = this.sjekk();
    }

    if(fecha.length == 10){
      var values = fecha.split("/");
      var dia = values[0];
      var mes = values[1];
      var ano = values[2];

      // cogemos los valores actuales
      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edad = (ahora_ano + 1900) - ano;
      if (ahora_mes < mes) {
          edad--;
      }
      if ((mes == ahora_mes) && (ahora_dia < dia)) {
          edad--;
      }
      if (edad > 1900) {
          edad -= 1900;
      }

      // calculamos los meses
      var meses = 0;

      if (ahora_mes > mes && dia > ahora_dia)
          meses = ahora_mes - mes - 1;
      else if (ahora_mes > mes)
          meses = ahora_mes - mes
      if (ahora_mes < mes && dia < ahora_dia)
          meses = 12 - (mes - ahora_mes);
      else if (ahora_mes < mes)
          meses = 12 - (mes - ahora_mes + 1);
      if (ahora_mes == mes && dia > ahora_dia)
          meses = 11;

      if (dia == 1 && mes == 1) {
        this.ageTarget.textContent = (parseInt(edad) - 1) + " - " + edad + " yrs old"
      }else  {
        this.ageTarget.textContent = edad + " yrs " + meses + " m";
      }

    }

  }

  sjekk(){
    var erdato = this.birthdate.value

    if (erdato.length == 4 && !erdato.includes("/")) {
      if (Date.parse("01/01/"+ erdato) != NaN) {
        return "01/01/" + erdato
      }
    }

    if (erdato.length == 10) {
      if(Date.parse(erdato) != NaN) {
        return erdato
      }
    }

    return "01/01/0000"
  }

  // Get Targets
  get birthdate() {
    return this.targets.find('birthdate')
  }




}
