
// Form Client side validations

import { ValidationController } from "stimulus-validation"

export default class extends ValidationController {

  static get rules() {
      return {
        name: { presence: { allowEmpty: false } },
        email: { presence: { allowEmpty: false }, email: true }
      };
    }

    static get validators() {
      return { agree: { attributes: ["terms"] } };
    }

    agree({ attr, value }) {
      if (!value) {
        this.errors.add(attr, "Must agree to terms");
      }
    }

    afterValidate({ el, attr }) {
      this.errorMessageEl(el).textContent = this.errorMessage(attr);
    }

    afterValidateAll(e) {
      e.preventDefault();
      console.log("validated", e.defaultPrevented);
    }

    errorMessageEl(el) {
      return el.closest(".field").querySelector(".error-message");
    }

    errorMessage(attr) {
      return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
    }



}
